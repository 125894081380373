import React from 'react';

import type { SVGProps } from '@atlaskit/icon/svg';
import SVG from '@atlaskit/icon/svg';

export const CalendarIcon = (props: SVGProps) => (
	<SVG size="small" {...props}>
		<rect width="24" height="24" fill="none" fillOpacity="0.01" />
		<g transform="scale(1.5,1.5)">
			<path
				d="M9.99984 1.33301C10.3417 1.33301 10.6235 1.59036 10.662 1.92192L10.6665 1.99967V2.66634H11.9998C12.7027 2.66634 13.2786 3.21024 13.3295 3.90015L13.3332 3.99967V11.9997C13.3332 12.7361 12.7362 13.333 11.9998 13.333H3.99984C3.26346 13.333 2.6665 12.7361 2.6665 11.9997V3.99967C2.6665 3.2633 3.26346 2.66634 3.99984 2.66634H5.33317V1.99967C5.33317 1.63148 5.63165 1.33301 5.99984 1.33301C6.34173 1.33301 6.62351 1.59036 6.66202 1.92192L6.6665 1.99967V2.66634H9.33317V1.99967C9.33317 1.63148 9.63165 1.33301 9.99984 1.33301ZM11.9998 7.33301H3.99984V11.9997H11.9998V7.33301ZM5.33317 3.99967H3.99984V5.99967H11.9998V3.99967H10.6665V4.66634C10.6665 5.03453 10.368 5.33301 9.99984 5.33301C9.65795 5.33301 9.37617 5.07566 9.33766 4.7441L9.33317 4.66634V3.99967H6.6665V4.66634C6.6665 5.03453 6.36803 5.33301 5.99984 5.33301C5.65795 5.33301 5.37617 5.07566 5.33766 4.7441L5.33317 4.66634V3.99967Z"
				fill="currentColor"
			/>
		</g>
	</SVG>
);
